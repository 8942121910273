import { SelectProps } from "@amzn/awsui-components-react/polaris/select";
import { MultiselectProps } from "@amzn/awsui-components-react/polaris/multiselect";
import { months } from "common/constants";

import { getPastYears } from "utils/date-utils";

export const YEARS_FILTER = getPastYears(6).map(
  (year) =>
    ({
      label: year.toString(),
      value: year.toString(),
    } as SelectProps.Option)
);

export const MONTHS_FILTER = months.map(
  (value) =>
    ({
      label: value,
      value,
    } as SelectProps.Option)
);

export const YEARS_MULTISELECT_FILTER =
  YEARS_FILTER as MultiselectProps.Option[];
export const MONTHS_MULTISELECT_FILTER =
  MONTHS_FILTER as MultiselectProps.Option[];

export const PROPERTY_FILTER_I18N_CONSTANTS = {
  filteringAriaLabel: "your choice",
  dismissAriaLabel: "Dismiss",

  filteringPlaceholder: "Find Use Tax Calculations",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",

  operationAndText: "and",
  operationOrText: "or",

  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",

  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",

  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text: any) => `Use: "${text}"`,
};
